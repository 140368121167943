<template>
  <div class="container">
    <a-button
      @click="goDashboard"
      style="border-radius: 20px; background-image: linear-gradient(to right, #1D2671 , #830051); color: white;margin-bottom: 10px;"
      ><LeftOutlined /> Back</a-button
    >
    <a-card class="profile-card">
      <a-form :model="profileData" class="mt-2">
        <!-- Contoh item form dengan layout kolom -->
        <div class="row form-item">
          <div class="col-12 col-sm-2 label-col"><label>Status Email :</label></div>
          <div class="col-12 col-sm-10">
            <a-tag color="green" v-if="profileData.emailApproved">
              <CheckCircleTwoTone two-tone-color="#52c41a" /> Email terverifikasi
            </a-tag>
            <a-tag color="red" v-else>
              <CloseCircleTwoTone two-tone-color="red" /> Email Belum terverifikasi
            </a-tag>
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Email :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.email" disabled /></div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>ID Pasien :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.code" disabled /></div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Nama Lengkap sesuai KTP :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.fullname" /></div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Tanggal Lahir sesuai KTP :</label></div>
          <div class="col-12 col-sm-10">
            <a-date-picker v-model:value="profileData.dateOfBirth"></a-date-picker>
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>No KTP :</label></div>
          <div class="col-12 col-sm-10"><a-input v-model:value="profileData.idNumber" /></div>
        </div>

        <!-- Unggah Foto KTP -->
        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Unggah foto KTP :</label></div>
          <div class="col-12 col-sm-10">
            <a-upload
              name="idPicture"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              :before-upload="beforeUpload"
              @change="handleChange"
              :disabled="profileData.idPicture"
            >
              <img
                v-if="profileData.idPicture"
                :src="backendUrl + profileData.idPicture"
                alt="id-picture"
                style="width: 200px; height: 200px;"
              />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
        </div>

        <!-- Foto Selfie -->
        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Foto Selfie :</label></div>
          <div class="col-12 col-sm-10">
            <a-upload
              name="avatar"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              :before-upload="beforeUpload"
              @change="handleChange"
              :disabled="profileData.selfiePicture"
            >
              <img
                v-if="profileData.selfiePicture"
                :src="backendUrl + profileData.selfiePicture"
                alt="avatar"
                style="width: 200px;height: 200px;"
              />
              <div v-else>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Jenis Kelamin :</label></div>
          <div class="col-12 col-sm-10">
            <a-radio-group v-model:value="profileData.gender">
              <a-radio value="male">Pria</a-radio>
              <a-radio value="female">Wanita</a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Nama Perwakilan :</label></div>
          <div class="col-12 col-sm-10">
            <a-input v-model:value="profileData.representativeName" />
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Telepon :</label></div>
          <div class="col-12 col-sm-10">
            <a-input v-model:value="profileData.representativePhone" />
          </div>
        </div>

        <div class="row form-item">
          <div class="col-sm-2 label-col"><label>Hubungan :</label></div>
          <div class="col-12 col-sm-10">
            <a-input v-model:value="profileData.representativeRelationship" />
          </div>
        </div>
        <div class="button-container text-left mt-4 w-100">
          <a-button
            type="primary"
            html-type="submit"
            class="custom-button-yes mr-2 my-2"
            :loading="loading"
            @click="uploadData"
          >
            <strong>Ubah Profile</strong>
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            class="custom-button-yes mr-2 my-2"
            :loading="loading"
            @click="handleChangePassword"
          >
            <strong>Ubah Password</strong>
          </a-button>
          <a-button
            type="primary"
            html-type="submit"
            class="custom-button-no my-2"
            :loading="loading"
            @click="logout"
          >
            <strong>Logout</strong>
          </a-button>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import { notification } from 'ant-design-vue'

import {
  LeftOutlined,
  LoadingOutlined,
  PlusOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons-vue'
import { getPatientProgram, updateProfile } from '../../services/axios/pulih'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
  components: {
    LoadingOutlined,
    PlusOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    LeftOutlined,
  },
  setup() {
    const profileData = ref({
      idPasien: '2023JKT0104',
      fullname: 'Test Aurora',
      dateOfBirth: '1999-05-22',
      idNumber: '123456789012355',
      gender: 'male',
      email: 'farizdenada@gmail.com',
      representativeName: 'Susu',
      representativePhone: '12345678',
      representativeRelationship: 'Aa',
    })
    const store = useStore()

    const router = useRouter()
    const backendUrl = ref('https://pulih-api-prod.digitala.id/')
    const loading = ref(false)
    const imageUrl = ref('')
    const fileList = ref([])

    const fetchMyData = async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)

      console.log('fetchMyData => ', res)
      profileData.value = res
      console.log(profileData.value.id)
    }

    onMounted(() => {
      fetchMyData()
    })
    const logout = () => {
      // TO DO DUMMY FUNCTION, UNCOMMENT LINE BELOW
      store.dispatch('user/LOGOUT')
      // router.push('/masuk')
    }
    const handleChangePassword = () => {
      router.push('/forgot-password')
    }
    const handleUpdateData = async () => {
      console.log(profileData.value, 'updatedData')
    }
    const handleChange = info => {
      if (info.file.status === 'uploading') {
        loading.value = true
        return
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, base64Url => {
          imageUrl.value = base64Url
          loading.value = false
        })
      }
      if (info.file.status === 'error') {
        loading.value = false
        message.error('Upload error')
      }
    }

    const beforeUpload = file => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG or PNG file!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
        return false
      }
      return isJpgOrPng && isLt2M
    }

    const getBase64 = (file, callback) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(file)
    }

    const uploadData = async () => {
      console.log('Profile Data:', profileData.value)
      const {
        dateOfBirth,
        email,
        fullname,
        gender,
        idNumber,
        idPicture,
        representativeName,
        representativePhone,
        representativeRelationship,
        selfiePicture,
        status,
        id,
      } = profileData.value
      await updateProfile(
        dateOfBirth,
        email,
        fullname,
        gender,
        idNumber,
        idPicture,
        representativeName,
        representativePhone,
        representativeRelationship,
        selfiePicture,
        status,
        id,
      )
      notification.success({
        message: 'Sukses Update Profile',
        duration: 5,
      })
      fetchMyData()
    }
    const goDashboard = () => {
      router.push('/')
    }
    return {
      goDashboard,
      logout,
      handleChangePassword,
      profileData,
      backendUrl,
      loading,
      imageUrl,
      fileList,
      handleChange,
      beforeUpload,
      uploadData,
    }
  },
}
</script>
<style lang="scss">
.new-password-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.form-item {
  display: flex;
  margin-bottom: 20px;
}

.label-col {
  text-align: right;
  padding-right: 15px;
}

.custom-button-yes {
  font-size: 14px;
  text-transform: uppercase;
  background: linear-gradient(to right, #eda702, #c76b1e) !important;
  border: 1px solid #eda702 !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #eda702 !important;
    background: transparent !important;
  }
}

@media (max-width: 576px) {
  .label-col {
    text-align: left;
    padding-bottom: 10px;
  }

  .form-item {
    flex-direction: column;
  }
}
</style>
